import JobSearchBar from "../jobs/JobSearchBar";
import styles from "./HomeLandingPage.module.css"

export default function LandingSearchBar({filters}) {
  return(
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-xl-9">
          <div className={styles['search-bar']}>
            <JobSearchBar filters={filters} version={"lander"}/>
          </div>
        </div>
      </div>
    </div>
  )
}