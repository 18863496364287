import { useEffect, useState, useRef } from "react";
import { useRouter } from "next/router";
import Select from "react-select";
import { FaSearch, FaTimes } from 'react-icons/fa'

import { selectConfig } from "../../configs/selectConfig"
import { OptionGenerator } from "../../lib/optionGenerator"

import styles from './JobSearchBar.module.css'
import { useDebounce } from "../../lib/useDebounce";


export default function JobSearchBar({filters, version}) {
  if(!filters) return <></>;

  const [query, setQuery] = useState("")
  const [filterData, setFilterData] = useState(new Map())
  const [initialFiltersSetup, setInitialFiltersSetup] = useState(false)
  const filterOptions = OptionGenerator.jobFilterOptions(filters, filterData)
  const router = useRouter()
  const debouncedQuery = useDebounce(query, 500)

  useEffect(() => {
    const newFilterData = new Map();

    for(const i in Object.keys(router.query)) {
      const key = Object.keys(router.query)[i];
      const values = (router.query[key] + "").split(",");

      for(const j in values) {
        const val = values[j];
        let found = "";

        if(key === "work_type" && filters.work_types != undefined && filters.work_types.length > 0 ) {
            found = filters.work_types.find((item) => {
              item.id === val || item.id + "" === val
            });
        } else if(key === "project_types" && filters.project_types != undefined && filters.project_types.length > 0) {
            found = filters.project_types.find((item) => {
              item.id === val || item.id + "" === val
            }) ;
        } else if(key === "industry" && filters.industries != undefined && filters.industries.length > 0) {
            found = filters.industries.find((item) => {
              item.id === val || item.id + "" === val
            }) ;
        } else if(key === "location" && filters.locations != undefined && filters.locations.length > 0) {
            found = filters.locations.find((item) => {
              item.id === val || item.id + "" === val
            });
        } else if(key === "min_years_of_experience" && filters.min_years_of_experience != undefined && filters.min_years_of_experience.length > 0) {
            found = filters.min_years_of_experience.find((item) => {
              item.id === val || item.id + "" === val
            });
        } else if(key === "title" &&
         filters.titles != undefined &&
          filters.titles.length > 0) {
            found = filters.titles.find((item) => {
              item.id === val || item.id + "" === val
            });
        };

        if(found) { newFilterData.set(key + "-" + found.id, { value: found.id, label: found.name }) };
        if(key === "min_years_of_experience" && values.length > 1) { break; }; 
      };
    };

    setFilterData(newFilterData);
    setQuery(router.query.q || "");
  }, [])
  
  useEffect(() => { setInitialFiltersSetup(true) }, [JSON.stringify([...filterData.keys()])])
  useEffect(() => { if (initialFiltersSetup && version !== "lander") { updateSearch() } }, [JSON.stringify([...filterData.keys()]), debouncedQuery])

  const addFilter = (filterType, filterOption) => {
    if(!filterOption) return;

    const newFilterData = new Map(filterData)
    const filterLabel =  filterOption.label;
    const filterValue =  filterOption.value;
    const filterId =  filterType + "-" + filterValue;

    if(filterType === "min_years_of_experience") {
      const filterKeys = [...newFilterData.keys()];
      const foundExpKey = filterKeys.find(key => key.includes("min_years_of_experience"));
      if(foundExpKey) newFilterData.delete(foundExpKey);

      newFilterData.set(filterId, { value: filterValue, label: filterLabel });
    } else {
      newFilterData.set(filterId, { value: filterValue, label: filterLabel });
    }
    setFilterData(newFilterData)
  }

  const removeFilter = (filterId) => {
    const newFilterData = new Map(filterData)
    newFilterData.delete(filterId)
    setFilterData(newFilterData)
  }
  
  const buildFilterBadges = () => {
    const elements = [...filterData.keys()].map(key => {
      const filterType = key.split("-")[0];
      const filterId = filterData.get(key).value;
      const filterLabel = filterData.get(key).label;

      return(
        <span key={JSON.stringify(key)} className={"badge badge-primary " + styles["filter-badge"]}>{filterLabel} <FaTimes onClick={() => removeFilter(key)} className={styles["filter-remove"]} style={{fontSize: "0.9rem", marginTop: -2, marginLeft: 5}}/></span>
      )
    })

    return elements;
  }

  const updateSearch = () => {
    let filterDataKeys = [...filterData.keys()];
    let filterTypes = new Map();

    filterDataKeys.forEach(key => {
      const filterType = key.split("-")[0];
      const filterValue = filterData.get(key).value;

      if(filterTypes.has(filterType)) {
        filterTypes.get(filterType).push(filterValue)
      } else {
        filterTypes.set(filterType, [filterValue]);
      }
    })
    
    let filterTypeKeys = [...filterTypes.keys()];
    let filterString = "/jobs?";

    if(query) filterString += `q=${query}&`;
    if(router.query.page) filterString += `page=1&`

    filterTypeKeys.forEach(key => {
      filterString += (key + "=" + filterTypes.get(key).join(",") + "&")
    })

    filterString = filterString.slice(0,-1);
    router.push(filterString, filterString, { shallow: true })
  }

  const buildFilters = () => {
    if(version === "lander") return <></>

    return(
      <>
        <hr/>
        <div className={styles['filters-area']}>
          <div className={styles['filter-dropdowns']}>
            <div className="row">
              <div className={"col-lg-2 " + styles['filter-container']}>
                <Select value={null} instanceId={"select-item"} styles={selectConfig} options={filterOptions.locations} placeholder={"Location"} onChange={(option) => addFilter("location", option)}/>
              </div>
              <div className={"col-lg-2 " + styles['filter-container']}>
                <Select value={null}  instanceId={"select-item"} styles={selectConfig} options={filterOptions.titles} placeholder={"Job Title"} onChange={(option) => addFilter("title", option)}/>
              </div>
              <div className={"col-lg-2 " + styles['filter-container']}>
                <Select value={null}  instanceId={"select-item"} styles={selectConfig} options={filterOptions.industries} placeholder={"Industry"} onChange={(option) => addFilter("industry", option)}/>
              </div>
              <div className={"col-lg-2 " + styles['filter-container']}>
                <Select value={null}  instanceId={"select-item"} styles={selectConfig} options={filterOptions.workTypes} placeholder={"Work Type"} onChange={(option) => addFilter("work_type", option)}/>
              </div>
              <div className={"col-lg-2 " + styles['filter-container']}>
                <Select value={null}  instanceId={"select-item"} styles={selectConfig} options={filterOptions.projectTypes} placeholder={"Project Type"} onChange={(option) => addFilter("project_types", option)}/>
              </div>
              <div className={"col-lg-2 " + styles['filter-container']}>
                <Select value={null}  instanceId={"select-item"} styles={selectConfig} options={filterOptions.minExperienceYears} placeholder={"Min Experience"} onChange={(option) => addFilter("min_years_of_experience", option)}/>
              </div>
            </div>
          </div>

          <div className={styles['filter-badges']}>
            {buildFilterBadges()}
          </div>
        </div>
      </>
    )
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    updateSearch();
  }

  const buildSearch = () => {
    if(version === "lander") {
      return(
        <div className={styles['query-area']}>
          <form onSubmit={handleSubmit}>
            <div className={styles['search-field']}>
              <div className={"input-group "}>
                <div className="input-group-prepend">
                  <span className="input-group-text" style={{borderRight: "none", paddingRight: 3, paddingTop: 6,  fontSize: "1.1rem"}}>
                    <FaSearch/>
                  </span>
                </div>
                <input type="text" className={"form-control " + styles['lander-search-field']} placeholder={"Search job title, company, industry..."} value={query} onChange={(e) => setQuery(e.target.value)}/>
                <div className={"input-group-append " + styles['lander-append']} style={{marginLeft: 10}}>
                  <button className={"btn btn-primary " + styles['search-btn'] + " " + styles['lander-search-btn']} onClick={() => updateSearch()}>Search</button>
                </div>
              </div>
              <button className={"btn btn-primary btn-block " + styles['lander-mobile-search-btn']} onClick={() => updateSearch()}>Search</button>
            </div>
          </form>
        </div>
      )
    } else {
      return(
        <div className={styles['query-area']}>
          <div className={styles['search-field']}>
            <div className={"input-group "}>
              <div className="input-group-prepend">
                <span className="input-group-text" style={{borderRight: "none", paddingRight: 0}}>
                  <FaSearch/>
                </span>
              </div>
              <input type="text" className="form-control" placeholder={"Search job title, company, industry..."} value={query} onChange={(e) => setQuery(e.target.value)}/>
            </div>
          </div>
          {/* <button className={"btn btn-primary " + styles['search-btn']} onClick={() => updateSearch()}>Search</button> */}
        </div>
      ) 
    }
  }

  const buildSearchHeader = () => {
    if(version !== "lander") {
      return <h2 className={"box-title " + styles['search-bar-title']}>{"Search"}</h2>
    } else {
      return <h2 className={"box-title " + styles['search-bar-title']} style={{fontSize: "1.3rem"}}>{"Search Jobs"}</h2>
    }
  }

  return(
    <div className={styles["job-search-bar"]}>
      <div className={"box " + styles['search-bar-box']}>
        {buildSearchHeader()}
        <div className={styles["search-form"]}>
          {buildSearch()}
          {buildFilters()}
        </div>
      </div>
    </div>
  )
}